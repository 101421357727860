import { ISLAND, ILLINOIS, HP, AMERI_HEALTH } from '../utils/getEnvironment';

export const SMS_OUTREACH_FLAGS = {
    SMS_DUE: 'SMS_DUE',
    SMS_SENT: 'SMS_SENT'
};

export const CALL_OUTREACH_FLAGS = {
    CALL_DUE: 'CALL_DUE',
    CALL_ATTEMPT: 'CALL_ATTEMPT',
    TALKED_TO: 'TALKED_TO'
};

export const TEXT_GREEN_COLOR = '#189016';
export const TEXT_RED_COLOR = '#FF1616';
export const TEXT_YELLOW_COLOR = '#DE8500';

export const TABLE_ROW_GREEN_COLOR = '#C6EFCE';
export const TABLE_ROW_RED_COLOR = '#FFC7CE';
export const TABLE_ROW_YELLOW_COLOR = '#FFEB9C';
export const DEFAULT_GROUP_NAME = 'town square';

export const TABLE_FONT_BLACK_COLOR = '#000000';
export const TABLE_FONT_GREEN_COLOR = '#3FBF61';
export const TABLE_FONT_RED_COLOR = '#EB4E2C';
export const TABLE_FONT_YELLOW_COLOR = '#FFB337';

export const TASK_STATUS = {
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED",
}

export const STYLE_OBJ_FOR_CERTAIN_GROUP = {
    border: '1px solid #FF4D00',
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingTop: '10px',
    paddingBottom: '10px',
};

export const APP_HEALTH_USER_FLAGS = {
    COMMON: 'COMMON',
    THIS_WEEK: 'THIS_WEEK',
    LAST_WEEK: 'LAST_WEEK'
};

export const CASELOAD_USER_FLAGS = {
    THIS_MONTH: 'THIS_MONTH',
    LAST_MONTH: 'LAST_MONTH',
    BOTH_MONTH: 'BOTH_MONTH'
};

export const MIXPANEL_REPORT_EVENTS = [
    {
        environment: ISLAND,
        userMessagesDataEvent: 'User Messages Data - Island'
    },
    {
        environment: ILLINOIS,
        userMessagesDataEvent: 'User Messages Data - Illinois'
    },
    {
        environment: HP,
        userMessagesDataEvent: 'User Messages Data - NewEngland'
    },
    {
        environment: AMERI_HEALTH,
        userMessagesDataEvent: 'User Messages Data - AmeriHealth'
    }
];

export const FILTER_BY_ALL_FORMS_FLAG = 'ALL';
export const FILTER_BY_BARC10_FORMS_FLAG = 'BARC10';
export const FILTER_BY_EXCEPT_BARC10_FORMS_FLAG = 'EXCEPT_BARC10';

export const SUBMITTED_FROM_TYPE_ADMIN = 'ADMIN';
export const SUBMITTED_FROM_TYPE_DIRECT = 'DIRECT';
export const SUBMITTED_FROM_TYPE_WAITING = 'WAITING';
export const SUBMITTED_FROM_TYPE_GROUP = 'GROUP';

export const FORM_NAME_BARC10 = 'BARC10';
export const FORM_NAME_ENCOUNTER_NOTE = 'Encounter Note (Deprecated)';
export const FORM_NAME_WELLNESS_PLAN = 'Wellness Journey (Hidden)';
export const PUBNUB_ENCRYPTION_KEY = 'ddd66c131e0f2f4da26a634a171199e0';

export const SORTING_ORDERS = {
    ascend: 'asc',
    descend: 'desc'
};

export const USERS_WAU_DATA_FETCH_FLAGS_TERRITORY = 'TERRITORY';
export const USERS_WAU_DATA_FETCH_FLAGS_SITES = 'SITES';
export const USER_STATUS_ALL_STATUS = 'ALL_STATUS';
export const TERRITORIES_TYPE_ALL_TERRITORIES = 'ALL_TERRITORIES';
export const TERRITORIES_TYPE_FLAGS = {
    DELAWARE: 'Delaware',
    NEW_ENGLAND: 'New England',
    ILLINOIS: 'Illinois',
    RHODE_ISLAND: 'Rhode Island',
    MASSACHUSETTS: 'Massachusetts',
    COMMUNITY: 'Community',
    RESEARCH: 'Research'
};

export const TERRITORY_TYPE_DEVELOPMENT = 'Development';

export const USER_BADGE_TYPES_ARR = [{
    label: 'Enrolled',
    value: 'ENROLLED'
},
{
    label: 'Eligible',
    value: 'ELIGIBLE'
},
{
    label: 'Not Members',
    value: 'NOT_ELIGIBLE'
}];

export const TASK_DATE_FORMAT = 'MMM DD YYYY'