import ApiService from "../utils/ApiService";
import AuthStore from "../stores/AuthStore";
import GroupStore from "../stores/Groups";
import MessagesStore from "../stores/MessagesStore";
import NotificationStore from "../stores/NotificationStore";

import SiteStore from "../stores/SiteStore";
import { getReactionType } from "../utils/getReactionType";
import { commonTrackEvent } from "../utils/Analytics";
import { slackNotification } from "../utils/sendSlackNotification";
import { message } from "../en.json";
import moment from "moment";
import { startsWith } from "lodash";
import { types } from "mobx-state-tree";
import { IS_NIDA, IS_CS, IS_HP } from "../utils/getEnvironment";
import { encryptedPublish } from "../utils/messageHandler";
import { APNS2_SETTINGS } from "../utils/apnsSettings";
import { getPubnubInstanceByUserType, publish } from "../utils/PubnubMethods";

const Message = types
  .model("Message", {
    entry: types.frozen({
      type: types.maybe(types.string),
      text: types.maybe(types.string),
      sender: types.maybe(types.string),
      groupId: types.maybeNull(types.number),
      userType: types.maybe(types.string),
      imgUrl: types.maybe(types.string),
    }),
    timetoken: types.maybe(types.string),
    reactions: types.map(types.map(types.boolean)),
  })
  .actions((self) => ({
    updateReactions(type, userId) {
      if (!self.reactions.has(type)) {
        self.reactions.set(type, {});
      }
      if (type === "delete" && self.isDeleted) {
        self.reactions.get(type).clear();
      } else if (self.reactions.get(type).has(userId)) {
        self.reactions.get(type).delete(userId);
      } else {
        self.reactions.get(type).set(userId, true);
      }
    },
    async saveReaction(reactionId, typeOfNeed, description) {
      const {
        selectedGroup,
        selectedGroup: { channel, name },
      } = MessagesStore;
      let type = getReactionType(reactionId);
      const userId = AuthStore.userId;
      let response = { success: false };
      let params;
      let senderId = -1;
      if (!self.entry.userId) {
        senderId = GroupStore.getUserId(selectedGroup.id, self.entry.sender);
        if (!senderId) {
          let fetchIdParams = {
            username: self.entry.sender,
            "$select[1]": "id",
          };
          const senderIdArray = await ApiService.getRequest(
            "users",
            fetchIdParams
          );
          if (senderIdArray.data.length) {
            senderId = senderIdArray.data[0].id;
          }
        }
      }
      params = {
        messageId: self.timetoken,
        channel: channel,
        reactionId: reactionId,
        senderId: self.entry.userId ? self.entry.userId : senderId,
      };

      if((reactionId === 7 || reactionId === 8) && typeOfNeed){
        params.typeOfNeed = typeOfNeed;
        params.description = description;
      }

      const restored = type === "delete" && self.isDeleted;
      if (
        restored ||
        (self.reactions.get(type) && self.reactions.get(type).has(userId))
      ) {
        response = await ApiService.deleteRequest(
          "message-reactions",
          userId,
          params
        );
      } else {
        params.userId = userId;
        params.isResolved = false;
        response = await ApiService.postRequest("message-reactions", params);
        if (response.success) {
          if (type === "flag") {
            self.sendFlagNotification(
              selectedGroup.name,
              selectedGroup.id,
              selectedGroup.channel
            );
          } else if (type === "like") {
            self.sendLikeNotification(
              selectedGroup.name,
              selectedGroup.channel
            );
          }
        }
        if (response.success && type === "flag") {
          if (IS_NIDA) {
            self.notificationNida(name, self.timetoken);
          } else if (IS_CS || IS_HP) {
            self.notificationCleanslate(name);
          }
        }
      }

      if (response.success) {
        type = restored ? "restored" : type;
        if (type === "flag") {
          if (startsWith(channel, "GROUP_CHAT")) {
            commonTrackEvent(
              "GROUP",
              "Flag Button Used in Group",
              selectedGroup.name,
              selectedGroup.id
            );
          } else if (startsWith(channel, "DIRECT_MESSAGE")) {
            commonTrackEvent(
              "DIRECT",
              "Flag Button Used in DM",
              selectedGroup.name,
              selectedGroup.id
            );
          }
        } else if (type === "delete") {
          if (startsWith(channel, "GROUP_CHAT")) {
            commonTrackEvent(
              "GROUP",
              "Delete Button Used in Group",
              selectedGroup.name,
              selectedGroup.id
            );
          } else if (startsWith(channel, "DIRECT_MESSAGE")) {
            commonTrackEvent(
              "DIRECT",
              "Delete Button Used in DM",
              selectedGroup.name,
              selectedGroup.id
            );
          }
          self.sendDeleteNotification(
            selectedGroup.name,
            selectedGroup.id,
            selectedGroup.channel
          );
        } else if (type === "like") {
          if (startsWith(channel, "GROUP_CHAT")) {
            commonTrackEvent(
              "GROUP",
              "Like Button Used in Group",
              selectedGroup.name,
              selectedGroup.id
            );
          } else if (startsWith(channel, "DIRECT_MESSAGE")) {
            commonTrackEvent(
              "DIRECT",
              "Like Button Used in DM",
              selectedGroup.name,
              selectedGroup.id
            );
          }
        }
      } else {
        NotificationStore.setNotification("error", message.reactionSaveError);
      }
    },
    async notificationCleanslate(name) {
      const groupResponse = await ApiService.getRequest(
        `groups/${MessagesStore.selectedGroup.id}`
      );
      if (groupResponse.success) {
        const groupSiteId = groupResponse.data.siteId;
        const siteName = SiteStore.getSiteNameFromAllSites(groupSiteId);
        let message = "";
        if (siteName) {
          message = `🔴 ${self.entry.sender} flagged a message in ${name}.📍 ${siteName}.`;
        } else {
          message = `🔴 ${self.entry.sender} flagged a message in ${name}.`;
        }
        slackNotification({ message });
      }
    },

    async notificationNida(name, timetoken) {
      const groupResponse = await ApiService.getRequest(
        `groups/${MessagesStore.selectedGroup.id}`
      );
      if (groupResponse.success) {
        const moderatorName = AuthStore.username;
        const groupSiteId = groupResponse.data.siteId;
        const siteName = SiteStore.getSiteNameFromAllSites(groupSiteId);
        let slackMessage = "";
        if (siteName) {
          slackMessage = `🔴 ${moderatorName} flagged a message in ${name}.📍 ${siteName}`;
        } else {
          slackMessage = `🔴 ${moderatorName} flagged a message in ${name}.`;
        }
        await ApiService.postRequest("message-flag-email", {
          groupName: name,
          flaggedUser: moderatorName,
          time: moment.unix(parseInt(timetoken) / 10000000).format("LT"),
        });
        // slackNotification(slackMessage);
      }
    },
    sendLikeNotification(name, channel) {
      const channelName = `USER_PUSH_${self.entry.userId}`;
      const messageBody = "Your message was liked";
      const channelType = channel.startsWith("GROUP")
        ? "GROUP"
        : channel.startsWith("WAITING")
        ? "WAITING"
        : "DIRECT";
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      const notificationData = {
        title: name,
        message: messageBody,
        body: messageBody,
        notificationType: `${channelType}-MESSAGE LIKE`,
        encrypted: false,
        channel,
        groupName: name,
      };
      const encryptedData = encryptedPublish(pubnub, {
			message: {
				pn_apns: {
					pn_push: [{ targets: [{ ...APNS2_SETTINGS }], version: "v2" }],
					aps: {
						alert: { title: name, body: messageBody },
						"mutable-content": 1,
						sound: "default",
					},
					info: { ...notificationData },
				},
				pn_fcm: {
					notification: { title: name, body: messageBody },
					android: { data: { ...notificationData }, notification: {sound: 'default'} },
				},
				//pn_gcm: { data: { ...notificationData }, notification: { ...notificationData } },
			},
			channel: channelName,
			storeInHistory: false,
		});
      pubnub.publish(encryptedData);
    },
    sendFlagNotification(name, id, channel) {
      let channelName;
      if (startsWith(channel, "GROUP_CHAT")) {
        channelName = `FLAG_MODERATOR_${id}`;
      } else {
        channelName = `FLAG_MODERATOR_DM_${id}`;
      }
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      const channelType = channel.startsWith("GROUP")
        ? "GROUP"
        : channel.startsWith("WAITING")
        ? "WAITING"
        : "DIRECT";
      const messageBody = self.entry.text
        ? `Flagged: ${self.entry.text}`
        : "A message was flagged";
      const notificationData = {
        title: name,
        message: messageBody,
        body: messageBody,
        encrypted: false,
        groupName: name,
        notificationType: `${channelType}-MESSAGE FLAG`,
        actionType: "MESSAGE_FLAG",
      };
      const encryptedData = encryptedPublish(pubnub, {
        message: {
          pn_apns: {
            pn_push: [{ targets: [{ ...APNS2_SETTINGS }], version: "v2" }],
            aps: {
              alert: { title: name, body: messageBody },
              "mutable-content": 1,
              sound: "default"
            },
            info: { ...notificationData },
          },
          pn_fcm: {
            notification: { title: name, body: messageBody },
            android: { data: { ...notificationData, channel }, notification: {sound: 'default'} },
          },
          //pn_gcm: { data: { ...notificationData, channel }, notification: { ...notificationData, channel} },
        },
        channel: channelName,
        storeInHistory: false,
      });
      pubnub.publish(encryptedData);
    },
    sendDeleteNotification(name, id, channel) {
      let channelName;
      if (startsWith(channel, "GROUP_CHAT")) {
        channelName = `DELETE_MODERATOR_${id}`;
      } else {
        channelName = `DELETE_MODERATOR_DM_${id}`;
      }
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      const channelType = channel.startsWith("GROUP")
        ? "GROUP"
        : channel.startsWith("WAITING")
        ? "WAITING"
        : "DIRECT";
      const messageBody = "Message has been deleted";
      const notificationData = {
        title: name,
        message: messageBody,
        body: messageBody,
        encrypted: false,
        groupName: name,
        notificationType: `${channelType}-MESSAGE DELETE`,
        actionType: "MESSAGE_DELETE",
      };
      const encryptedData = encryptedPublish(pubnub, {
        message: {
          pn_apns: {
            pn_push: [{ targets: [{ ...APNS2_SETTINGS }], version: "v2" }],
            aps: {
              alert: { title: name, body: messageBody },
              "mutable-content": 1,
              sound: "default"
            },
            info: { ...notificationData },
          },
          pn_fcm: {
            notification: { title: name, body: messageBody },
            android: { data: { ...notificationData, channel }, notification: {sound: 'default'} },
          },
          //pn_gcm: { data: { ...notificationData, channel }, notification: { ...notificationData, channel} },
        },
        channel: channelName,
        storeInHistory: false,
      });
      pubnub.publish(encryptedData);
    },
  }))
  .views((self) => ({
    getReactionsCount(type) {
      return self.reactions.has(type) ? self.reactions.get(type).size : 0;
    },
    hasReacted(type) {
      return (
        self.reactions.has(type) &&
        self.reactions.get(type).has(AuthStore.userId)
      );
    },
    get isDeleted() {
      return (
        self.reactions.has("delete") && self.reactions.get("delete").size > 0
      );
    },
  }));
export default Message;
