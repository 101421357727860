import HmacSHA256 from "crypto-js/hmac-sha256";
import Pubnub from "pubnub";
import pubnubInstances from "./pubnubInstance";
import MessagesStore from "../stores/MessagesStore.js";
import * as Sentry from "@sentry/browser";
import _ from "lodash";
import { signalHandler } from "./signalHandler";
import AuthStore from "../stores/AuthStore.js";
import NotificationStore from "../stores/NotificationStore.js";
import FormMessageStore from "../stores/FormMessageStore.js";
import { add } from "./addTimetokenHelper";
import MemberListStore from "../stores/MemberListStore";
import GroupStore from "../stores/Groups";
import SiteStore from "../stores/SiteStore";
import { sub } from "./subtractTimetokenHelper";
import GroupListStore from "../stores/GroupListStore.js";
import { commonTrackEvent } from "./Analytics";
import { encryptedPublish, decryptMessage } from "./messageHandler";
import { getBatchedArray } from "./getBatchArray";
import { APNS2_SETTINGS } from "./apnsSettings";
import { IS_ISLAND, IS_ILLINOIS, currentSiteName } from "../utils/getEnvironment";
import ApiService from "./ApiService";
const {
  REACT_APP_PUBKEY: pubKey,
  REACT_APP_SUBKEY: subKey,
  REACT_APP_SECRET_KEY: secretKey,
} = process.env;
const mixpanel = require("mixpanel-browser");

let existingListener = null;

export const getPubnubInstanceByUserType = (userType) => {
  return ["moderator", "SA"].includes(userType)
    ? pubnubInstances.moderator
    : pubnubInstances.personal;
};

export const createUserPubnubInstance = (params) => {
  const { userId } = params;
  const pubnub = new Pubnub({
    publishKey: pubKey,
    subscribeKey: subKey,
    useRandomIVs: IS_ISLAND ? true : false,
    uuid: userId.toString(),
  });
  pubnub.setAuthKey(getAuthKey(userId));

  return pubnub;
};

export const setUUID = (pubnub, id) => {
  pubnub.setUUID(id);
};

export const publish = (pubnub, message) => {
  const {
    selectedGroup: { channel, name, id, userType, isIntersiteGroup },
  } = MessagesStore;
  const { userId, username } = AuthStore;
  const channelType = channel.startsWith("GROUP")
    ? "GROUP"
    : channel.startsWith("WAITING")
      ? "WAITING"
      : "DIRECT";
  const msg = message ? message.replace(/\n/g, " ") : message;
  const dataToBePassed = {
    message: {
      type: "text",
      encrypted: true,
      text: message,
      sender: username,
      userId: userId,
      userType: userType,
    },
    channel,
  }
  const notificationData = {
		message: {
			pn_apns: {
				pn_push: [{ targets: [{ ...APNS2_SETTINGS }], version: "v2" }],
				aps: {
					alert: {
						title: name,
						body: msg,
					},
					"mutable-content": 1,
					sound: "default",
				},
				info: {
					message: `${username}: ${message}`.replace(/\n/g, " "),
					body: `${username}: ${message}`.replace(/\n/g, " "),
					notificationType: `${channelType}-TEXT MESSAGE`,
					title: name,
					channel,
					encrypted: false,
					groupName: name,
				},
			},
			//pn_gcm: {
			//	data: {
			//		message: `${username}: ${message}`.replace(/\n/g, " "),
			//		body: `${username}: ${message}`.replace(/\n/g, " "),
			//		notificationType: `${channelType}-TEXT MESSAGE`,
			//		title: name,
			//		encrypted: false,
			//		channel,
			//		groupName: name,
			//		sender: username,
			//	},
			//	notification: {
			//		message: `${username}: ${message}`.replace(/\n/g, " "),
			//		body: `${username}: ${message}`.replace(/\n/g, " "),
			//		notificationType: `${channelType}-TEXT MESSAGE`,
			//		title: name,
			//		encrypted: false,
			//		channel,
			//		groupName: name,
			//		sender: username,
			//	},
			//},
			pn_fcm: {
				notification: {
					title: name,
					body: `${username}: ${message}`.replace(/\n/g, " "),
				},
				android: {
					data: {
						message: `${username}: ${message}`.replace(/\n/g, " "),
						body: `${username}: ${message}`.replace(/\n/g, " "),
						notificationType: `${channelType}-TEXT MESSAGE`,
						title: name,
						channel,
						groupName: name,
						sender: username,
					},
					notification: {
						sound: "default",
					},
				},
			},
		},
		storeInHistory: false,
		channel,
  };
  const encryptedData = dataToBePassed;
  const encryptedNotificationData = notificationData;

  if (channelType == "GROUP" && isIntersiteGroup) {
    // The case in which the message is in the particular chat group
    // Then make the api request to the Backend server
    let params = {
      message: dataToBePassed.message,
      channel: dataToBePassed.channel,
      channelType: channelType,
      userId: dataToBePassed.message.userId,
      notificationData: notificationData,
      groupName: name,
      groupId: id,
      currentSite: currentSiteName
    }

    ApiService.postRequest("inter-site", params)
      .then(r => {
        channelType === "DIRECT"
          ? commonTrackEvent("DIRECT", "Send Message In DM", name, id)
          : commonTrackEvent("GROUP", "Send Message In Group", name, id);
      })
      .catch(e => {
        Sentry.captureException(new Error(`Error on publish Text :: ${e}`));
        channelType === "DIRECT"
          ? commonTrackEvent("DIRECT", "Message fails to send in DM", name, id)
          : commonTrackEvent(
            "GROUP",
            "Message fails to send in Group",
            name,
            id
          );
        NotificationStore.setNotification(
          "error",
          "The following message wasn't sent",
          message,
          0
        );

      })


    return new Promise((resolve, reject) => {
      pubnub.publish(encryptedNotificationData);
      pubnub.publish(encryptedData, (s, r) => {
        if (!s.error) {
          channelType === "DIRECT"
            ? commonTrackEvent("DIRECT", "Send Message In DM", name, id)
            : commonTrackEvent("GROUP", "Send Message In Group", name, id);
          resolve(true);
        } else {
          Sentry.captureException(new Error(`Error on publish Text :: ${s}`));
          channelType === "DIRECT"
            ? commonTrackEvent("DIRECT", "Message fails to send in DM", name, id)
            : commonTrackEvent(
              "GROUP",
              "Message fails to send in Group",
              name,
              id
            );
          NotificationStore.setNotification(
            "error",
            "The following message wasn't sent",
            message,
            0
          );
          reject(false);
        }
      });
    })


  } else if (!isIntersiteGroup) {
    // The case in which the message is not in the intersite group

    return new Promise((resolve, reject) => {
      pubnub.publish(encryptedNotificationData);
      pubnub.publish(encryptedData, (s, r) => {
        if (!s.error) {
          channelType === "DIRECT"
            ? commonTrackEvent("DIRECT", "Send Message In DM", name, id)
            : commonTrackEvent("GROUP", "Send Message In Group", name, id);
          resolve(true);
        } else {
          Sentry.captureException(new Error(`Error on publish Text :: ${s}`));
          channelType === "DIRECT"
            ? commonTrackEvent("DIRECT", "Message fails to send in DM", name, id)
            : commonTrackEvent(
              "GROUP",
              "Message fails to send in Group",
              name,
              id
            );
          NotificationStore.setNotification(
            "error",
            "The following message wasn't sent",
            message,
            0
          );
          reject(false);
        }
      });
    })
  };
}

export const getOnlineUsers = (pubnub, channel) => {
  return new Promise((resolve, reject) => {
    pubnub
      .hereNow({
        channels: [channel],
        includeUUIDs: true,
        includeState: true,
      })
      .then((response) => {
        resolve(response.channels);
      })
      .catch((error) => { });
  });
};

export const fetchViewInContext = (pubnub, callback = null, messageList) => {
  const {
    selectedGroup: {
      channel,
      addOldMessage,
      start,
      setLoading,
      setStart,
      setEnd,
      setHasMore,
      fetchReactions,
    },
    setViewportMessagesLoaded,
  } = MessagesStore;
  let count = 5;
  let prevScrollHeight;
  setLoading(true);
  pubnub
    .history({
      channel,
      start: sub(String(start), "1"),
      reverse: true,
      count,
      stringifiedTimeToken: true,
    })
    .then((response) => {
      count = 4;
      setEnd(add(response.endTimeToken, "1"));
      _.reverse(response.messages).map((message) => {
        if (message.entry.encrypted) {
          const decrypted = decryptMessage(pubnub, _.cloneDeep(message));
          return addOldMessage(decrypted);
        } else {
          return addOldMessage(message);
        }
      });

      prevScrollHeight = messageList ? messageList.current.scrollHeight : null;
      pubnub
        .history({
          channel,
          start,
          reverse: false,
          count,
          stringifiedTimeToken: true,
        })
        .then((response) => {
          setViewportMessagesLoaded(true);
          setStart(response.startTimeToken);
          fetchReactions();
          if (response.messages.length < count) {
            setHasMore(false);
          }
          _.reverse(response.messages).map((message) => {
            if (message.entry.encrypted) {
              const decrypted = decryptMessage(pubnub, _.cloneDeep(message));
              return addOldMessage(decrypted);
            } else {
              return addOldMessage(message);
            }
          });
        })
        .then(() => {
          if (callback) {
            callback(prevScrollHeight);
          }
        });
    })
    .catch((error) => { });
};

export const getMessageInfo = (pubnub, data) => {
  return new Promise((resolve, reject) => {
    pubnub
      .history({
        channel: data.channel,
        stringifiedTimeToken: true,
        start: sub(data.messageId, "1"),
        end: add(data.messageId, "1"),
      })
      .then((response) => {
        const decryptedMessages = response.messages.map((message) => {
          if (message.entry.encrypted) {
            return decryptMessage(pubnub, _.cloneDeep(message));
          } else return message;
        });
        response.messages = decryptedMessages;
        resolve(response);
      })
      .catch((error) => {
        Sentry.captureException(
          new Error(`Error on Fetching MessageInfo :: ${error.message}`)
        );
        reject(error);
      });
  });
};

export const sendNotificationAndSaveUserForms = (
  pubnub,
  formId,
  response,
  channel
) => {
  const { timetoken } = response;
  const channelType = channel.split("_")[0];
  const FORM_TITLE = "Survey";
  const FORM_MESSAGE = "You have a new survey to complete";
  const FORM_ACTION_TYPE = "NEW_FORM";
  const data = {
    encrypted: false,
    message: FORM_MESSAGE,
    body: FORM_MESSAGE,
    title: FORM_TITLE,
    channelName: channel,
    actionType: FORM_ACTION_TYPE,
    formId,
    notificationType: `${channelType}-FORM`,
    messageId: timetoken,
  };
  const publishPayload = {
    message: {
      pn_apns: {
        pn_push: [{ targets: [{ ...APNS2_SETTINGS }], version: "v2" }],
        aps: {
          "mutable-content": 1,
          sound: "default",
          alert: { title: FORM_TITLE, body: FORM_MESSAGE },
        },
        info: { ...data },
      },
	  pn_fcm: {
		notification: { title: FORM_TITLE, body: FORM_MESSAGE },
		android: { data: { ...data }, notification: {sound: 'default'} },
	},
    //  pn_gcm: { data: { ...data }, notification: { ...data } },
    },
    storeInHistory: false,
    channel,
  };
  const encryptedData = encryptedPublish(pubnub, publishPayload);
  pubnub
    .publish(encryptedData)
    .then((res) => {
      let userFormPromises = [];
      if (channelType === "GROUP") {
        userFormPromises = FormMessageStore.saveUserFormsForGroup(
          formId,
          channel,
          response
        );
      } else if (channelType === "DIRECT") {
        userFormPromises = FormMessageStore.saveUserFormsForDms(
          formId,
          channel,
          response
        );
      } else if (channelType === "WAITING") {
        userFormPromises = FormMessageStore.saveUserFormsForWaitingRoom(
          formId,
          channel,
          response
        );
      }

      Promise.all(userFormPromises).catch(() => {
        const start = sub(String(response.timetoken), "1");
        const end = add(String(response.timetoken), "1");
        pubnub.deleteMessages({ channel, start, end }, (result) => { });
      });
    })
    .catch((err) => { });
};

export const publishForm = (pubnub, formId) => {
  const {
    selectedGroup: { channel, userType },
  } = MessagesStore;
  const { userId, username } = AuthStore;
  const encryptedData = encryptedPublish(pubnub, {
    message: {
      encrypted: true,
      type: "form",
      formId: formId,
      sender: username,
      userId: userId,
      userType: userType,
    },
    channel,
  });

  pubnub
    .publish(encryptedData)
    .then(async (response) => {
      await sendNotificationAndSaveUserForms(pubnub, formId, response, channel);
    })
    .catch((err) => { });
};

export const getToken = (position) => {
  const {
    selectedGroup: { messages },
  } = MessagesStore;
  const { timetoken } =
    position === "bottom" ? messages[messages.length - 1] : messages[0];
  return timetoken;
};

export const fetchBottomMessages = (pubnub, callback = null) => {
  const {
    selectedGroup: {
      channel,
      fetchBottomMessagesReactions,
      addNewerMessages,
      setHasBottomMessages,
    },
  } = MessagesStore;
  const latestToken = getToken("bottom");
  const count = 25;
  pubnub
    .history({
      channel,
      start: latestToken,
      reverse: true,
      count,
      stringifiedTimeToken: true,
    })
    .then((response) => {
      fetchBottomMessagesReactions(
        response.startTimeToken,
        add(response.endTimeToken, "1")
      );
      if (response.messages.length < count) {
        setHasBottomMessages(false);
      }
      response.messages.map((message) => {
        if (message.entry.encrypted) {
          const decrypted = decryptMessage(pubnub, _.cloneDeep(message));
          return addNewerMessages(decrypted);
        } else {
          return addNewerMessages(message);
        }
      });
    })
    .catch((err) => {
      Sentry.captureException(
        new Error(`Error on Fetching BottomMessages :: ${err.message}`)
      );
    });
};

export const fetchLastMessage = (pubnub, channels, isResolved) => {
  const { addLastMessages } = MemberListStore;
  subscribeChannels(pubnub, channels);
  pubnub
    .fetchMessages({
      channels,
      start: (Date.now() * 10000).toString(),
      count: 1,
    })
    .then((response) => {
      Object.keys(response.channels).map((key) => {
        const obj = response.channels[key][0];

        if (obj.message.encrypted) {
          const decrypted = decryptMessage(pubnub, _.cloneDeep(obj.message));
          return addLastMessages(
            obj.channel,
            obj.timetoken,
            decrypted,
            isResolved
          );
        } else {
          return addLastMessages(
            obj.channel,
            obj.timetoken,
            obj.message,
            isResolved
          );
        }
      });
    })
    .catch((error) => { });
};

export const subscribeSingleChannelWithPresence = (pubnub, channel) => {
  pubnub.subscribe({
    channels: [channel],
    withPresence: true,
    error: function (error) {
      Sentry.captureException(
        new Error(
          `Error on subscribeSingleChannelWithPresence  :: ${JSON.stringify(
            error
          )}`
        )
      );
    },
  });
};

export const addListener = (pubnub, callback = null) => {
  if (existingListener) {
    if (callback === null) {
      return;
    }
    pubnub.removeListener(existingListener);
  }
  existingListener = {
    message: (encryptedChannelMessage) => {
      const channelMessage = decryptMessage(pubnub, encryptedChannelMessage);
      const { selectedGroup } = MessagesStore;
      if (channelMessage.message.messageType === "SIGNAL") {
        if (signalHandler) {
          signalHandler(channelMessage.message);
        }
      } else {
        const newMsgObj = {
          timetoken: channelMessage.timetoken,
          entry: channelMessage.message,
        };
        if (channelMessage.channel === selectedGroup.channel) {
          let bannerIds = [];
          let formIds = [];
          if (channelMessage.message.type === "add") {
            bannerIds.push({
              groupId: channelMessage.message.groupId,
            });
          }
          if (channelMessage.message.type === "form") {
            formIds.push({
              formId: channelMessage.message.formId,
              timetoken: channelMessage.timetoken,
            });
          }
          setTimeout(() => fetchFormsData(formIds), 500);
          FormMessageStore.getUserForms(formIds);
          FormMessageStore.getFormName(formIds);
          FormMessageStore.getCountOfFormSubmittedByUserService(formIds);
          FormMessageStore.getCountOfFormSentToUsersService(formIds);
          GroupStore.fetchBanner(bannerIds);
          selectedGroup.addMessage(newMsgObj);
        }

        if (
          channelMessage &&
          channelMessage.message &&
          channelMessage.message.pn_gcm &&
          channelMessage.message.pn_gcm.data
        ) {
          if (channelMessage.message.sender !== AuthStore.username) {
            showBrowserNotification(channelMessage.message.pn_gcm.data);
            if (
              !(
                channelMessage.message.messageType === "SIGNAL" ||
                (Object.keys(channelMessage.message).length === 2 &&
                  channelMessage.message.hasOwnProperty("pn_gcm") &&
                  channelMessage.message.hasOwnProperty("pn_apns"))
              )
            ) {
              if (_.startsWith(channelMessage.channel, "WAITING_ROOM")) {
                MemberListStore.toggleUnread(channelMessage);
              } else {
                MessagesStore.setUnreadMessage(channelMessage.channel);
              }
            }
          }
        }
        MessagesStore.saveLatestMessage(channelMessage.channel, newMsgObj);
        MessagesStore.sortLatestMessages();
        if (_.startsWith(channelMessage.channel, "DIRECT_MESSAGE")) {
          AuthStore.sortDms();
        } else {
          AuthStore.sortGroups();
          SiteStore.sortSelectedSiteGroups();
          SiteStore.sortAllGroups();
        }

        MessagesStore.toggleNewMessage();
        if (callback) {
          callback();
        }
      }
    },
    presence: (presence) => {
      const {
        selectedGroup: { channel, saveLastSeen },
      } = MessagesStore;
      const { updateOnlineStatus } = GroupListStore;
      if (presence && presence.state) {
        if (presence.state.isOnline) {
          updateOnlineStatus(true, presence.uuid);
        } else {
          updateOnlineStatus(false, presence.uuid);
        }
      }
    },
  };
  pubnub.addListener(existingListener);
};

export const showBrowserNotification = (notificationData) => {
  try {
    const title = notificationData.title;
    const options = {
      body: notificationData.message,
      tag: notificationData.channel,
      icon: "favicon.ico",
    };
    let notification = false;

    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        mixpanel.track("Notification Permission Granted", { from: "WEB" });
        notification = new Notification(title, options);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
          mixpanel.track("Notification Permission Denied", { from: "WEB" });
          if (permission === "granted") {
            notification = new Notification(title, options);
          }
        });
      }
      if (notification) {
        notification.onclick = () => {
          window.focus();
          notification.close();
        };
      }
    }
  } catch (err) { }
};

export const fetchFormsData = (formIds) => {
  FormMessageStore.getUserForms(formIds);
  FormMessageStore.getFormName(formIds);
  FormMessageStore.getCountOfFormSubmittedByUserService(formIds);
  FormMessageStore.getCountOfFormSentToUsersService(formIds);
};

export const history = (pubnub, callback = null, prevScrollHeight = null) => {
  const {
    selectedGroup: {
      channel,
      addOldMessage,
      start,
      setHistoryLoading,
      setStart,
      setEnd,
      setHasMore,
      messages,
      fetchReactions,
    },
  } = MessagesStore;

  const count = 25;
  setHistoryLoading(true);
  pubnub
    .history({
      channel,
      start,
      reverse: false,
      count,
      stringifiedTimeToken: true,
    })
    .then((response) => {
      if (!messages.length) {
        setStart(response.startTimeToken);
      } else {
        setStart(response.startTimeToken);
        setEnd(add(response.endTimeToken, "1"));
      }
      fetchReactions();
      if (response.messages.length < count) {
        setHasMore(false);
      }
      let formIds = [],
        bannerIds = [];
      _.reverse(response.messages).map((message) => {
        if (message.entry.encrypted) {
          const decrypted = decryptMessage(pubnub, _.cloneDeep(message));
          if (decrypted.entry.type === "form") {
            formIds.push({
              formId: decrypted.entry.formId,
              timetoken: decrypted.timetoken,
            });
          } else if (decrypted.entry.type === "add") {
            bannerIds.push({
              groupId: decrypted.entry.groupId,
            });
          }
          return addOldMessage(decrypted);
        } else {
          if (message.entry.type === "form") {
            formIds.push({
              formId: message.entry.formId,
              timetoken: message.timetoken,
            });
          } else if (message.entry.type === "add") {
            bannerIds.push({
              groupId: message.entry.groupId,
            });
          }
          return addOldMessage(message);
        }
      });
      FormMessageStore.getUserForms(formIds);
      FormMessageStore.getFormName(formIds);
      FormMessageStore.getCountOfFormSubmittedByUserService(formIds);
      FormMessageStore.getCountOfFormSentToUsersService(formIds);
      GroupStore.fetchBanner(bannerIds);
      if (callback && prevScrollHeight) {
        callback(prevScrollHeight);
      }
      setHistoryLoading(false);
    })
    .catch((err) => {
      Sentry.captureException(
        new Error(`Error on Fetching History :: ${err.message}`)
      );
      setHistoryLoading(false);
    });
};

export const subscribeSingleChannel = (pubnub, channel) => {
  pubnub.subscribe({
    channels: [channel],
    autoload: 100,
    error: function (error) {
      Sentry.captureException(
        new Error(
          `Error on subscribeSingleChannel  :: ${JSON.stringify(error)}`
        )
      );
    },
  });
};

export const unsubscribeSingleChannel = (pubnub, channel) => {
  pubnub.unsubscribe({
    channels: [channel],
  });
};

export const createModeratorPubnubInstance = (params) => {
  const { userId } = params;
  const pubnub = new Pubnub({
    publishKey: pubKey,
    subscribeKey: subKey,
    useRandomIVs: IS_ISLAND ? true : false,
    uuid: userId.toString(),
  });
  pubnub.setAuthKey(getModAuthKey());

  return pubnub;
};

export const unsubscribeAll = () => {
  if (pubnubInstances.moderator) {
    pubnubInstances.moderator.unsubscribeAll();
  }
  if (pubnubInstances.personal) {
    pubnubInstances.personal.unsubscribeAll();
  }
};

export const subscribeModeratorChannels = () => {
  const pubnub = pubnubInstances.moderator;
  if (!pubnub) {
    return;
  }
  let channelsToSubscribe = [];
  channelsToSubscribe.push("ALERTS"); // For signal
  channelsToSubscribe.push("MODERATOR"); // For signal
  subscribeChannels(pubnub, channelsToSubscribe);
};

export const subscribeUserChannels = (params) => {
  const { userId } = params;
  const pubnub = pubnubInstances.personal;
  if (!pubnub) {
    return;
  }

  let channelsToSubscribe = [];
  channelsToSubscribe.push(`USER_PUSH_${userId}`); // For signal
  subscribeChannels(pubnub, channelsToSubscribe);
};

export const subscribeChannels = (pubnub, channels) => {
  if (!pubnub) {
    return;
  }
  const batchedChannels = getBatchedArray(channels);
  batchedChannels.forEach((batch) => {
    pubnub.subscribe({ channels: batch, withPresence: true });
    setStateInChannel(pubnub, batch, {
      isOnline: false,
    });
  });
};

export const subscribeAllGroupChannels = (pubnub, channels) => {
  if (!pubnub) {
    return;
  }
  const batchedChannels = getBatchedArray(channels);
  batchedChannels.forEach((batch) => {
    setTimeout(() => {
      pubnub.subscribe({ channels: batch });
    }, 500);
  });
};

export const subscribeDMs = (pubnub, channels) => {
  if (!pubnub) {
    return;
  }
  const batchedChannels = getBatchedArray(channels);
  batchedChannels.forEach((batch) => {
    pubnub.subscribe({ channels: batch, withPresence: true });
  });
};

export const getAuthKey = (userId) => {
  return HmacSHA256(`USER_ID_${userId}`, secretKey).toString();
};

export const getModAuthKey = () => {
  return HmacSHA256("MODERATOR", secretKey).toString();
};

export const setStateInChannel = (pubnub, channel, newState) => {
  pubnub.setState(
    {
      state: newState,
      channels: [channel],
    },
    function (status, response) {
      // handle status, response
    }
  );
};

export const publishBanner = (pubnub, groupId) => {
  const {
    selectedGroup: { channel, userType },
  } = MessagesStore;
  const { userId, username } = AuthStore;
  const encryptedData = encryptedPublish(pubnub, {
    message: {
      type: "add",
      encrypted: true,
      groupId: groupId,
      sender: username,
      userId: Number(userId),
      userType: userType,
    },
    channel,
  });
  return new Promise((resolve, reject) => {
    pubnub.publish(encryptedData, (s, r) => {
      if (!s.error) {
        resolve(true);
      } else {
        Sentry.captureException(new Error(`Error on publish Banner :: ${s}`));
        reject(false);
      }
    });
  });
};

export const publishImage = (pubnub, url) => {
  const {
    selectedGroup: { channel, name, userType },
  } = MessagesStore;
  const { userId, username } = AuthStore;
  const channelType = channel.startsWith("GROUP")
    ? "GROUP"
    : channel.startsWith("WAITING")
      ? "WAITING"
      : "DIRECT";
  const encryptedData = encryptedPublish(pubnub, {
    message: {
      type: "img",
      encrypted: true,
      imgUrl: url,
      sender: username,
      userId: userId,
      userType: userType,
    },
    channel,
  });
  const encryptedNotificationData = encryptedPublish(pubnub, {
		message: {
			pn_apns: {
				pn_push: [{ targets: [{ ...APNS2_SETTINGS }], version: "v2" }],
				aps: {
					alert: {
						title: name,
						body: "Photo",
					},
					"mutable-content": 1,
					sound: "default",
				},
				info: {
					message: "Photo",
					body: "Photo",
					notificationType: `${channelType}-IMAGE`,
					title: name,
					channel,
					encrypted: false,
					groupName: name,
					mediaUrl: url,
					mediaType: url.split(".").pop(),
				},
			},
			//  pn_gcm: {
			//    data: {
			//  message: "Photo",
			//  body: "Photo",
			//  notificationType: `${channelType}-IMAGE`,
			//  title: name,
			//  encrypted: false,
			//  channel,
			//  groupName: name,
			//  sender: username,
			//    },
			//    notification: {
			//      message: "Photo",
			//      body: "Photo",
			//      notificationType: `${channelType}-IMAGE`,
			//      title: name,
			//      encrypted: false,
			//      channel,
			//      groupName: name,
			//      sender: username,
			//      image: url
			//    }
			//  },
			pn_fcm: {
				notification: { title: name, body: "Photo" },
				android: {
					data: {
						message: "Photo",
						body: "Photo",
						notificationType: `${channelType}-IMAGE`,
						title: name,
						channel,
						groupName: name,
						sender: username,
					},
					notification: { sound: "default" },
				},
			},
		},
		storeInHistory: false,
		channel,
  });
  return new Promise((resolve, reject) => {
    pubnub.publish(encryptedNotificationData);
    pubnub.publish(encryptedData, (s, r) => {
      if (!s.error) {
        resolve(true);
      } else {
        Sentry.captureException(new Error(`Error on publish Image :: ${s}`));
        reject(false);
      }
    });
  });
};

export const publishCustomBanner = (pubnub, message, emojiName) => {
  const {
    selectedGroup: { channel, userType },
  } = MessagesStore;
  const { userId, username } = AuthStore;
  const encryptedData = encryptedPublish(pubnub, {
    message: {
      encrypted: true,
      type: "customBanner",
      emojiName: emojiName ? emojiName : "partyPopper",
      message: message,
      sender: username,
      userId: Number(userId),
      userType: userType,
    },
    channel,
  });
  return new Promise((resolve, reject) => {
    pubnub.publish(encryptedData, (s, r) => {
      if (!s.error) {
        resolve(true);
      } else {
        Sentry.captureException(new Error(`Error on publish Banner :: ${s}`));
        reject(false);
      }
    });
  });
};

export const latestMessage = (pubnub, channel) => {
  const { saveLatestMessage } = MessagesStore;

  return pubnub.history(
    {
      channel,
      reverse: false,
      count: 1,
      stringifiedTimeToken: true,
    },
    (status, response) => {
      if (!status.error) {
        response.messages.map((message) => {
          if (message.entry.encrypted) {
            const decrypted = decryptMessage(pubnub, _.cloneDeep(message));
            return saveLatestMessage(channel, decrypted);
          } else {
            return saveLatestMessage(channel, message);
          }
        });
      } else {
        Sentry.captureException(
          new Error(`Error on Fetching latestMessage :: ${status}`)
        );
      }
    }
  );
};
