import ApiService from "../utils/ApiService";
import AuthStore from "../stores/AuthStore";
import DmStore from "../stores/DMs";
import GroupStore from "../stores/Groups";
import MemberListStore from "../stores/MemberListStore";
import MessagesStore from "../stores/MessagesStore";
import NotificationStore from "../stores/NotificationStore";
import { commonTrackEvent } from "../utils/Analytics";
import includes from "lodash/includes";
import { publish, getPubnubInstanceByUserType } from "../utils/PubnubMethods";
import { types } from "mobx-state-tree";
import ProfileStore from "../stores/ProfileStore";

const Broadcast = types
  .model("Broadcast", {
    selectedChannels: types.array(types.number),
    selectedUsers: types.map(types.string),
    userOptions: types.map(
      types.frozen({
        username: types.string,
        type: types.string,
        count: types.number,
      })
    ),
    filteredOptions: types.map(
      types.frozen({
        username: types.string,
        type: types.string,
        count: types.number,
      })
    ),
    groupUsers: types.map(
      types.array(
        types.frozen({
          userId: types.number,
          type: types.string,
        })
      )
    ),
    dmLoading: false,
    loading: false,
    broadcastInProgress: false,
    modFilter: false,
    message: "",
  })
  .actions((self) => ({
    async toggleChannelStatus(channelId) {
      const userId = AuthStore.userId;
      if (self.isChannelSelected(channelId)) {
        const index = self.selectedChannels.indexOf(channelId);
        self.selectedChannels.splice(index, 1);
        self.groupUsers.get(channelId) &&
          self.removeUserOptions(self.groupUsers.get(channelId));
      } else {
        self.setLoading(true);
        let data = [];
        let users = [];
        self.selectedChannels.push(channelId);
        if (self.groupUsers.has(channelId)) {
          users = self.groupUsers.get(channelId);
        } else {
          data = await GroupStore.fetchGroupUsers(channelId);
          data.forEach((groupUser) => {
            if (groupUser.userId === userId) {
              return;
            } else {
              if (groupUser.userId && groupUser.username) {
                const userObject = {
                  userId: groupUser.userId,
                  type: groupUser.userType,
                  username: groupUser.username,
                };
                users.push(userObject);
                self.addGroupUser(channelId, userObject);
              }
            }
          });
        }
        await self.addUserOptions(users);
        self.setLoading(false);
      }
      self.applyFilter();
    },
    clearFilteredOptions() {
      self.filteredOptions.clear();
    },
    toggleUserSelection(userId, username) {
      if (self.selectedUsers.has(userId)) {
        self.selectedUsers.delete(userId);
      } else {
        self.selectedUsers.set(userId, username);
      }
    },
    addGroupUser(groupId, user) {
      const users = self.groupUsers.get(groupId) || [];
      users.push(user);
      self.groupUsers.set(groupId, users);
    },
    async addUserOptions(users) {
      //const userIds = users.map(user => user.userId);
      //const usernameMap = await MemberListStore.getUsernames(userIds);
      users.forEach((user) => {
        self.addUserOption(user);
        self.addSelectedUser(user);
      });
    },
    addSelectedUser(user) {
      if (user.username && !self.selectedUsers.has(user.userId)) {
        self.selectedUsers.set(user.userId, user.username);
      }
    },
    addUserOption(user) {
      let newCount = 1;

      if (self.userOptions.has(user.userId)) {
        const userOption = self.userOptions.get(user.userId);
        newCount = userOption.count + 1;
        self.userOptions.set(user.userId, {
          ...userOption,
          count: newCount,
        });
      } else {
        self.userOptions.set(user.userId, {
          username: user.username,
          type: user.type,
          count: newCount,
        });
      }
    },
    removeUserOptions(users) {
      users.forEach((user) => {
        self.removeUserOption(user);
      });
    },
    setBroadcastProgress(value) {
      self.broadcastInProgress = value;
    },
    removeUserOption(user) {
      let userOption = self.userOptions.get(user.userId);
      if (userOption) {
        if (userOption.count <= 1) {
          self.selectedUsers.delete(user.userId);
          self.userOptions.delete(user.userId);
        } else {
          const newCount = userOption.count - 1;
          self.userOptions.set(user.userId, {
            ...userOption,
            count: newCount,
          });
        }
      }
    },
    applyFilter() {
      self.filteredOptions.clear();
      const iterator = self.userOptions.keys();
      let count = 0;
      const size = self.userOptions.size;
      while (count < size) {
        const key = iterator.next().value;
        if (!self.modFilter) {
          self.filteredOptions.set(key, self.userOptions.get(key));
        } else if (
          self.modFilter &&
          (self.userOptions.get(key).type === "moderator" ||
            self.userOptions.get(key).type === "semi-moderator")
        ) {
          self.filteredOptions.set(key, self.userOptions.get(key));
        }

        count += 1;
      }
      self.filteredOptions.delete(AuthStore.userId);
    },
    filterSelectedUsers() {
      const iterator = self.selectedUsers.keys();
      let count = 0;
      const deleteKeys = [];
      const size = self.selectedUsers.size;
      while (count < size) {
        const key = iterator.next().value;
        if (!self.filteredOptions.has(key)) {
          deleteKeys.push(key);
        }
        count += 1;
      }
      deleteKeys.forEach((key) => self.selectedUsers.delete(key));
    },
    toggleModFilter() {
      self.modFilter = !self.modFilter;
      self.applyFilter();
    },
    setMessage(e) {
      self.message = e.target.value;
    },
    resetSelections() {
      self.selectedChannels = [];
      self.selectedUsers.clear();
      self.userOptions.clear();
      self.filteredOptions.clear();
      self.groupUsers.clear();
      self.modFilter = false;
      self.message = "";
    },
    setLoading(value) {
      self.loading = value;
    },
    async createDm(username, userId) {
      if (!userId) {
        return;
      }
      const ownId = AuthStore.userId;
      const ownUsername = AuthStore.username;
      const userType = AuthStore.type;
      self.setDmLoading(true);
      if (userId === ownId) {
        NotificationStore.setNotification(
          "error",
          "You cannot create Dm with yourself"
        );
        self.setDmLoading(false);
        return;
      } else {
        const data = [
          { id: userId, username: username },
          { id: ownId, username: ownUsername },
        ];
        const endPoint = "create-dm";
        const payload = [...data];
        const response = await ApiService.postRequest(endPoint, payload);
        if (response.success) {
          const dm = response.data.dm;
          const channelId = dm.id;
          const dmName = dm.name;
          ProfileStore.changeSelectedAction("Dm creation");
          MessagesStore.changeChannel(channelId, dmName, userType, null, "dm");
          commonTrackEvent("DIRECT", "Create DM", dmName, channelId);
          const userChannel = response.data.dmUsers.find(
            (o) => o.userId === ownId
          );
          if (userChannel) {
            const channelData = { ...dm, dm_channel_users: userChannel };
            AuthStore.addUserDm(channelData);
          }

          self.setDmLoading(false);
        } else {
          ProfileStore.changeSelectedAction("Dm creation");
          const channelId = response.data.id;
          const dmName = response.data.name;
          MessagesStore.changeChannel(channelId, dmName, userType, null, "dm");

          self.setDmLoading(false);
        }
      }
    },
    async startDm() {
      const ownId = AuthStore.userId;
      const ownUsername = AuthStore.username;
      const userType = AuthStore.type;
      const size = self.selectedUsers.size;
      const keys = self.selectedUsers.keys();
      const usernames = self.selectedUsers.values();
      self.setBroadcastProgress(true);
      let count = 0;
      while (count < size) {
        count += 1;
        const userId = Number(keys.next().value);
        const username = usernames.next().value;
        const data = [
          { id: userId, username: username },
          { id: ownId, username: ownUsername },
        ];
        const endPoint = "create-dm";
        const payload = [...data];
        const response = await ApiService.postRequest(endPoint, payload);
        if (response.success) {
          const dm = response.data.dm;
          const channelId = dm.id;
          const dmName = dm.name;
          MessagesStore.changeChannel(channelId, dmName, userType, null, "dm");
          MessagesStore.publishHelper(channelId, dmName, "dm");
          const pubnub = getPubnubInstanceByUserType(self.type);
          await publish(pubnub, self.message);
          commonTrackEvent("DIRECT", "Create DM", dmName, channelId);
          const userChannel = response.data.dmUsers.find(
            (o) => o.userId === ownId
          );
          if (userChannel) {
            const channelData = { ...dm, dm_channel_users: userChannel };
            AuthStore.addUserDm(channelData);
          }
          self.setBroadcastProgress(false);
        } else {
          const channelId = response.data.id;
          const dmName = response.data.name;
          MessagesStore.changeChannel(channelId, dmName, userType, null, "dm");
          MessagesStore.publishHelper(channelId, dmName, "dm");
          const pubnub = getPubnubInstanceByUserType(self.type);
          await publish(pubnub, self.message);
          self.setBroadcastProgress(false);
        }
      }
    },
    // async createDm(username, userId) {
    //   if (!userId) {
    //     return;
    //   }
    //   const userType = AuthStore.type;
    //   self.setDmLoading(true);
    //   if (AuthStore.username === username) {
    //     NotificationStore.setNotification(
    //       "error",
    //       "You cannot create Dm with yourself"
    //     );
    //     return null;
    //   } else {
    //     const dmName = AuthStore.username + "," + username;
    //     const existingDm = self.dmExists(dmName);
    //     if (existingDm) {
    //       MessagesStore.changeChannel(
    //         existingDm.id,
    //         existingDm.name,
    //         userType,
    //         null,
    //         "direct"
    //       );
    //     } else {
    //       const response = await ApiService.postRequest("dm-channels", {
    //         name: dmName.split(",").sort().join(","),
    //       });
    //       if (response.success) {
    //         AuthStore.addUserDm(response.data);
    //         const channelId = response.data.id;
    //         MessagesStore.changeChannel(
    //           channelId,
    //           response.data.name,
    //           userType,
    //           null,
    //           "dm"
    //         );
    //         commonTrackEvent(
    //           "DIRECT",
    //           "Create DM",
    //           response.data.name,
    //           channelId
    //         );
    //         DmStore.addUserToDm(channelId, AuthStore.userId);
    //         DmStore.addUserToDm(channelId, userId);
    //       } else {
    //         NotificationStore.setNotification(
    //           "error",
    //           `${broadcast.dmCreateError} ${username}`
    //         );
    //       }
    //     }
    //     ProfileStore.changeSelectedAction("Dm creation");
    //     self.setDmLoading(false);
    //     return true;
    //   }
    //   self.setDmLoading(false);
    // },

    setDmLoading(value) {
      self.dmLoading = value;
    },
    async startWaitingChat(username, userId) {
      MemberListStore.makeread(userId);
      const userType = AuthStore.type;
      MessagesStore.setIsWaiting(true);
      MessagesStore.changeChannel(userId, username, userType, null, "waiting");
    },
    // async startDm() {
    //   const userType = AuthStore.type;
    //   const size = self.selectedUsers.size;
    //   const keys = self.selectedUsers.keys();
    //   const usernames = self.selectedUsers.values();
    //   let count = 0;
    //   let userId, username, dmName, existingDm;
    //   self.setBroadcastProgress(true);
    //   while (count < size) {
    //     count += 1;
    //     userId = keys.next().value;
    //     username = usernames.next().value;
    //     dmName = AuthStore.username + "," + username;
    //     existingDm = self.dmExists(dmName);

    //     if (existingDm) {
    //       MessagesStore.publishHelper(existingDm.id, existingDm.name, "dm");
    //       await PubNubService.publish(self.message);
    //     } else {
    //       const response = await ApiService.postRequest("dm-channels", {
    //         name: dmName.split(",").sort().join(","),
    //       });

    //       if (response.success) {
    //         existingDm = response.data;
    //         AuthStore.addUserDm(response.data);
    //         const channelId = response.data.id;
    //         MessagesStore.publishHelper(channelId, response.data.name, "dm");
    //         await PubNubService.publish(self.message);
    //         DmStore.addUserToDm(channelId, AuthStore.userId);
    //         DmStore.addUserToDm(channelId, userId);
    //       } else {
    //         NotificationStore.setNotification(
    //           "error",
    //           `${broadcast.messageNotSent} ${username}`
    //         );
    //       }
    //     }
    //   }
    //   if (existingDm) {
    //     MessagesStore.changeChannel(
    //       existingDm.id,
    //       existingDm.name,
    //       userType,
    //       null,
    //       "dm"
    //     );
    //   }
    //   self.setBroadcastProgress(false);
    // },
    // dmExists(dmName) {
    //   const sortedDmName = dmName.split(",").sort().join(",");
    //   const similarDms = AuthStore.dms.filter(
    //     (dm) => dm.name.length === dmName.length
    //   );
    //   const length = similarDms.length;
    //   let index = 0;

    //   while (index < length) {
    //     if (
    //       similarDms[index].name.split(",").sort().join(",") === sortedDmName
    //     ) {
    //       return similarDms[index];
    //     }
    //     index += 1;
    //   }
    //   return false;
    // },
  }))
  .views((self) => ({
    isChannelSelected(channelId) {
      return includes(self.selectedChannels, channelId);
    },
    isUserSelected(userId) {
      return self.selectedUsers.has(userId);
    },
  }));
export default Broadcast;
